import React from "react"
import Footer from "../../components/Footer/Footer";

const styles = require("../../styles/pages/General.module.scss");


export default function PrivacyPage() {
    return (
        <div>
            <section>
                <h1>Privacy Policy</h1>
                <h2>1. Introduction</h2>
                <span>Last updated June 4th, 2021</span>

                <p>The privacy and security of your data is the most important and foundational part of our service to you. We take the responsibility very seriously. We’ve tried to keep this policy as easy to understand as possible, you should finish reading it and know exactly how your data is used and all of your rights associated with it.  </p>

                <p>This privacy policy applies to all of the software products and websites associated with Prestavi which are collectively known as the "services" and are owned and controlled by Prestavi, Inc known as "we", "us", "our". This privacy policy governs how we collect, process, store, and transfer your data. We will never sell your data. If you do not agree with any part of this policy then you should not use our services. </p>
            </section>

            <section>
                <h2>2. Data collection and usage</h2>

                <p>With any and all of the information we collect, we’ll never sell your personal information to third parties.</p>

                <h3>2.1 When you sign up for an account </h3>
                <p>
                    Personal data we collect when you sign up for an account:
                </p>

                <ul>
                    <li>Name</li>
                    <li>Email Address</li>
                    <li>Password (stored in a hash for complete privacy)</li>
                    <li>Profile Image (if uploaded)</li>
                    <li>IP Address</li>
                    <li>Company Name</li>
                    <li>Country</li>
                    <li>Use Case Information</li>
                </ul>

                <p>Some or all of this information is used in the following ways:</p>

                <ul>
                    <li>Stored in our databases, backups, and cache storage systems to provide you service and access to your account. </li>
                    <li>Processed with our transactional email service provider in order to deliver transactional type email messages to you (for example, to confirm your email address or help you reset your password).</li>
                    <li>Processed with our error logging and monitoring service for any errors that happen while you use our services. </li>
                    <li>Processed with own Internal system for managing your account such as sending you invoices and providing support services. </li>
                    <li>Processed with our real-time message service provider to notify us relevant activity.  </li>
                    <li>Added to an email marketing list. You can opt-out at any time. </li>
                    <li>Your email could be used for our targeted remarketing campaigns on social media. </li>
                    <li>Added to our CRM for sales to communicate and collaborate with you. </li>
                    <li>We may use your name and/or company in our marketing materials unless you explicitly opt-out.</li>
                </ul>

                <h3>2.2	When you use our services </h3>
                <p><strong>The data you enter into your account is entirely yours.</strong> When you enter data into your account, effectively using the service, we store and process that content in the following ways:</p>

                <ul>
                    <li>Stored and encrypted at rest in our databases, backups, and cache storage systems in order to provide you with service. </li>
                    <li>Processed for internal retrieval of usage and volume-based metrics (for example: the number of jobs started last month) for billing and product engagement tracking purposes. </li>
                    <li>Processed with our error logging and monitoring service to diagnose and monitor any errors that happen while you use the service.  </li>
                    <li>Processed for internal-only research and development of future improvements and product features. </li>
                </ul>

                <h3>2.3	When you become a customer </h3>

                <p>
                    When you subscribe to a paid plan and become a customer, we collect the following additional information:
                </p>
                <ul>
                    <li>Billing address</li>
                    <li>Payment details, including banking or credit card information</li>
                </ul>

                <p>This information is used in the following ways: </p>

                <ul>
                    <li>Payment details are transferred and stored securely with our PCI-compliant payment service provider. This information never touches our servers. We need this to charge you for the service. </li>
                    <li>Your billing address is stored in our databases and is used to calculate any taxes due and prevent payment fraud.</li>
                </ul>

                <h3>2.4	When you visit our websites </h3>

                <p>As a visitor of our websites, we automatically collect the following information: </p>

                <ul>
                    <li>IP address (which is derived as geolocation information)</li>
                    <li>Device information (operating system, type of browser and version) that is shared automatically from your browser when you browse a website. We track this information, along with the pages you’ve visited, page load timing and which website referred you for marketing and performance analytical reasons.</li>
                </ul>

                <p>This information is used in the following ways: </p>

                <ul>
                    <li>Processed with our web analytics service provider to help us better measure and understand our website traffic. </li>
                    <li>Stored in our access logs to help us monitor our website traffic for malicious activity and understand our traffic volume for capacity planning.  </li>
                    <li>Processed by our customer support tools, such as live chat, to provide you with help if you need it. </li>
                </ul>

                <h3>2.5	When you contact us from our websites</h3>
                <p>When you complete a contact form, we collect the following information: </p>
                <ul>
                    <li>Name</li>
                    <li>Email address (to reply to you) </li>
                    <li>Organization name </li>
                    <li>Message</li>
                </ul>

                <p>This information is used in the following ways:</p>

                <ul>
                    <li>Stored and processed internally and by our third-party service providers to allow us to read, respond to your message using email, and keep historical records of our interactions with you.  </li>
                </ul>

                <h3>2.6	Information we do not collect </h3>

                <p>We do not collect any information that is deemed protected, such as age, race, religion, gender identity, sexual orientation, or physical or mental abilities or disabilities. You may enter this type of data into your account as part of your general use of the service and it’s handled the same way as other user-generated content in your account. </p>

                <h3>2.7	Information about children </h3>

                <p>Our services are not intended or targeted for children under the age of 16. We do not knowingly collect information about children under the age of 16. </p>

                <h3>2.8	How we share your information </h3>
                <p>We will never sell, trade, or lease your data. It’s yours and you own the sole rights to it. Our revenue comes solely from selling subscription and support services.  </p>
                <p>We may share some of your data with third party service providers (also known as subprocessors) to assist us in providing you service or help us with basic business functions. You can view a list of our service providers here. </p>
                <p>Prestavi, Inc. is an American company. If U.S. law enforcement authorities have the necessary warrant, criminal subpoena, or court order requiring we share data, we must comply. If we are audited by a tax authority, then we may be required to share billing related information. </p>
            </section>

            <section>
                <h2>3. Your data rights </h2>
                <p>At Prestavi, we apply the same set of data rights to everyone, regardless of their location. We follow and comply with the laws set forth in the European Union’s General Data Protection Regulation (GDPR) and the Health Insurance Portability and Accountability Act (HIPAA.) These are currently some of the strictest privacy centric regulation and laws.  </p>

                <p>You have the following data rights as a user of our services:</p>

                <ul>
                    <li><strong>The right to be informed.</strong> You have the right to know what and how we collect and use your data. This privacy policy provides all of the details about that information. </li>
                    <li><strong>The right of access.</strong> You have the right to access your personal data (commonly referred to as subject access). If you suddenly stop paying for your subscription, we have the right to prevent access to your account until you pay any balance owed.  </li>
                    <li><strong>The right of rectification.</strong> You have the right to have inaccurate personal information be corrected.  </li>
                    <li><strong>The right of erasure.</strong> You have the right to have your personal data erased, known as the Right to be Forgotten, subject to certain limitations under applicable law. If you request that any of your data be deleted, we will effectively close your account and delete all of your data within our possession and, by extension, all of our service providers within thirty days. </li>
                    <li><strong>The right to restrict processing.</strong> You have the right to request the restriction of your data under certain circumstances, such as to opt-out of our email marketing lists. </li>
                    <li><strong>The right to data portability.</strong> You have the right to obtain the personal information we have about you and transfer it to another service provider. </li>
                    <li><strong>The right to object.</strong> You have the right, in certain situations, to object to how or why your personal information is processed. </li>
                    <li><strong>The right not to be subjected to automated decision-making.</strong> You have the right to object and prevent any decision that could have a significant consequence on you made solely based on an automated process. This right is limited if the decision is necessary for performance of any contract between you and us, is allowable by applicable law, or is based on your explicit consent. </li>
                    <li><strong>The right to not be discriminated against for invoking your rights.</strong> We will not provide a different quality of service or offer different pricing because you have exercised your data privacy rights. However, by exercising certain rights, such as the right of erasure, may in effect prevent you from using our services. </li>
                    <li><strong>The right to complain.</strong> You have the right to complain to the appropriate supervisory authority regarding the handling of your personal information. If you have a complaint about our use of your information, we would prefer that you to contact us directly in the first instance so that we can address your complaint. If you are in the European Union, you can file a complaint on the EDPS website found <a
                        href="https://edps.europa.eu/data-protection/our-role-supervisor/complaints_en" target="_blank">here</a>. </li>
                </ul>
            </section>

            <section>
                <h2>4. Transfer of your information to the U.S. and other countries</h2>

                <p>Prestavi is an American company, and our data infrastructure is currently only based in the United States. That means that everyone who chooses to use our service will have their data transferred to and stored in the United States. The European Union has stronger privacy laws than the United States and a core part of the GDPR is that when any personal data of European Union citizens is transferred out of the European Union, it must be protected to the same level as guaranteed under European Union law. </p>
            </section>

            <section>
                <h2>5. Retaining your information </h2>
                <p>We retain your personal information when we have an ongoing legitimate business need to do so, for example, to provide you service or comply with applicable tax, legal and accounting requirements.  </p>
                <p>When you opt to close your account or cancel your subscription, all of the information stored in your account is deleted within thirty days.  </p>
            </section>

            <section>
                <h2>6. Updates to this policy</h2>
                <p>This policy may be updated from time to time to reflect changing legal, regulatory, or operational requirements. We encourage you to periodically review this page for the latest information on our privacy practices. If you do not accept any changes made to this policy, please discontinue use of our websites and services.</p>
            </section>

            <section>
                <h2>7. Contact us </h2>
                <p>If you have questions regarding this policy or our privacy practices, please contact us by email at <span className={styles.Highlight}>support@prestavi.com</span>.</p>
            </section>

            <Footer />
        </div>
    )
}

